@use "uswds-core" as *;
@use 'variables' as *;

.crt-landing--separator {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 2px solid color($theme-color-warning);
  border-radius: 2px;
  background: color($theme-color-warning);
  width: 50px;
}

.crt-landing--separator_small {
  @extend .crt-landing--separator !optional;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.crt-landing--separator_sub {
  background: $theme-color-primary-darker;
  border: 2px solid color($theme-color-primary-darker);
  border-radius: 2px;
  width: 25px;
  margin-bottom: 1rem;
}
