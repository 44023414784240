@use "uswds-core" as *;
@use 'variables' as *;

$arrow-height: 24px;

.crt-landing--section:not(.crt-landing--hero) {
  padding-bottom: 1.5rem;

  @include at-media-max(mobile-lg) {
    &.crt-landing--alert {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  h1 {
    margin-top: 1rem;
    margin-bottom: 0;
    line-height: 1.3;
  }

  h2 {
    margin-top: 0.5rem;
    margin-bottom: 0;

    @include at-media-max(mobile-lg) {
      margin-top: 0;
    }
  }
}

.crt-landing--section {
  &__item {
    &:not(:last-child) {
      @include at-media-max(mobile-lg) {
        margin-bottom: 2.5rem;
      }
    }
  }
}

.crt-landing--hero {
  padding-top: 0;

  .width-full {
    width: 100%;
  }

  @include at-media(desktop) {
    background-image: url($theme-hero-image);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &.crt-landing-topics--hero {
      background-image: url('../images/laws-and-regs-lp.jpeg');
    }
  }

  @include at-media(widescreen) {
    min-height: 345px;
  }

  @media (min-width: 1700px) {
    background-position: center;
  }
}

.crt-landing--alert {
  &_header {
    color: color($theme-color-warning);
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .crt-button--large {
    background: color($theme-color-warning);
    color: color($theme-color-primary-darker) !important;

    &:hover {
      background: color($theme-color-warning-dark);
    }
  }
}

.crt-landing--goldtext {
  color: color($theme-color-warning);
}

.crt-landing--lead {
  @include text--heading__large();
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  @include at-media-max(mobile-lg) {
    margin-bottom: 2rem;
  }
}

.crt-landing--largetext {
  @include text--heading__medium();
  @include at-media-max(mobile-lg) {
    @include text--body-copy__medium();
  }
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.crt-landing--smalltext {
  @include text--body-copy__small();
}

.crt-landing--how_to_report,
.crt-landing--whats_on_ada,
.crt-landing--service_animals {
  .crt-landing--largetext {
    @include at-media-max(mobile-lg) {
      font-size: $theme-heading-font-size-lg-mobile;
      line-height: 1.3;
      margin-bottom: 2rem;
    }
  }

  .crt-landing--icon_gold {
    @include flex-container();
    border-radius: 2rem;
    justify-content: center;
    min-height: 32px;
    min-width: 32px;
    width: 32px;
    height: 32px;
    background-color: color($theme-color-warning);
    margin-right: 1rem;
  }

  .lp-tile {
    .crt-landing--icon_grey {
      min-height: 67px;
      min-width: 67px;
      width: 67px;
      height: 67px;
      margin-top: 0;

    img {
      max-width: 40px;
      max-height: 40px;
    }
  }
  }

  .crt-landing--icon_grey {
    @include flex-container();
    border-radius: 2rem;
    justify-content: center;
    min-height: 26px;
    min-width: 26px;
    width: 26px;
    height: 26px;
    background-color: #e1e7f1;
    margin-right: 1rem;

    img {
      max-width: 24px;
      max-height: 24px;
    }
  }

  .crt-landing--reporting_column {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;

    @include at-media-max(mobile-lg) {
      margin-top: 0;
    }

    h3 {
      min-height: 3rem;
      margin-top: 0;

      @include at-media-max(desktop) {
        min-height: unset;
      }
    }
  }
}

.page-title-icon {
  .crt-landing--icon_grey {
    @include flex-container();
    border-radius: 2rem;
    justify-content: center;
    min-height: 67px;
    min-width: 67px;
    width: 67px;
    height: 67px;
    background-color: #e1e7f1;
    margin-right: 1rem;

    img {
      max-width: 40px;
      max-height: 40px;
    }
  }
}

.crt-landing--submit {
  padding-top: calc(2rem - #{$arrow-height});

  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;

    @include at-media-max(mobile-lg) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  p.crt-landing--text {
    @include text--body-copy__medium;
    margin-bottom: 0;

    a {
      font-weight: bold;
    }
  }
}

.crt-landing--arrow {
  width: 100px;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: $arrow-height solid color($theme-color-primary-darker);
}

.crt-landing--whats_on_ada {
  a:focus, a:hover, a:visited {
    color: white;
    font-weight: bolder;
  }
}

.crt-landing--paragraph-wrapper {

  @media(max-width: 1023px) {
    max-width: none;
  }

  @media(min-width: 1024px) {
    max-width: 45ex;
  }

}

