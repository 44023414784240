@use 'uswds-core' as *;
@use 'variables' as *;

.carousel,
.slide {
  padding: 0;
  margin: 0;
}

.carousel {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.carousel .slide-container {
    height: 500px;
}

.carousel ul {
  margin: 0;
  padding: 0;
}
.carousel .controls {
    height: 0;
}

.carousel .hidden {
    visibility: hidden;
}

.slide {
  height: 500px;
  background-size: cover;
  position: relative;
  margin-bottom: 1em;
  width: 90%;
}

.slide p {
  background-color: rgba(255, 255, 255, 0.8);
}

.carousel.active {
  height: 600px;
  position: relative;
}

.active .slide {
  display: block;
  position: absolute;
  top: 0;
  left: 85%;
  z-index: 200;
  visibility: hidden;
  transition: left 0.6s ease-out;
  width: 85%;
}

.slide.current {
  display: block;
  z-index: 500;
}

.btn-prev,
.btn-next {
  height: 83%;
  position: absolute;
  z-index: 700;
  top: 0;
  border: 0;
  background: transparent;
  width: 50%;
}

.btn-prev {
  left: 0;
}

.btn-next {
  right: 0;
}

.carousel .slidenav {
  display: flex;
  margin: 16px;
}

.slidenav li {
  display: inline-block;
  margin: 0 2px;
  width: 25%;
}

.slidenav button {
  background-color: #919191;
  border: solid 1px #919191;
  height: 8px;
  width: 100%;
}

.slidenav button.current {
  background-color: #265D9D;
  border: solid 1px black;
}

.slidenav button:hover,
.slidenav button:focus {
  background-color: #265D9D;
}

.slidenav button.current:hover,
.slidenav button.current:focus {
  background-color: #265D9D;
}

.active .slide.current {
  visibility: visible;
  left: 0;
}

.active .slide.next {
  visibility: visible;
  left: 85%;
}

.active .slide.prev {
  left: -85%;
}

.active .slide.next.in-transition,
.active .slide.prev.in-transition {
  visibility: visible;
}

.featured-topics {
  display: none;
}

@include at-media(tablet) {
  .carousel {
    display: none;
  }
  .featured-topics {
    display: block;
  }
}
