.crt-banner--beta {
  a {
    font-weight: bold;
    white-space: nowrap;
  }

  .usa-tag {
    margin-right: 0.5rem !important;
  }
}
