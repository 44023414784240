@use "uswds-core" as *;
@use 'variables' as *;
@use 'separator' as *;

.crt-page {
  padding-top: 2rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;

  @include at-media(desktop) {
    &.compact {
      max-width: 64rem;
      margin-left: 0px;
    }
  }

  @media(min-width: 95rem) {
    &.compact {
      margin-left: auto;
      margin-right: auto;
      max-width: 95rem;
      padding-right: calc(95rem - 64rem);
      background-color: white;
    }
  }

  @media(min-width: 64rem) and (max-width: 95rem) {
    &.compact {
      max-width: 64rem;
      background-color: white;
    }
  }

  .fac-icon.usa-icon {
    width: 36px;
    height: 36px;
    min-width: 36px;
  }

  .search-box-wrapper {
    position: relative;
    margin-bottom: 1em;
    padding-bottom: 10px;
    background-color: white;
    z-index: 1;
    position: absolute;
    top: 0;
    width: calc(100% - 3rem);

    svg.usa-icon {
      height: 24px;
      width: 24px;
    }

    &.mobile-search-box {
      display: none;
    }

    .result-number {
      color: #71767A;
    }

    .search {
      display: block;
      position: absolute;
      left: 8px;
      top: 16px;
      width: 24px;
      height: 24px;
    }

    a#next-result, a#prev-result, a.searchbtn, a.clear {
      padding: 0;
      display: flex;
      cursor: pointer;
      &:hover {
        background-color: transparent;
      }
      &.display-none {
        display: none;
      }
      &.disabled {
        cursor: auto;
        color: #71767A;
      }
    }

    a#next-result, a.searchbtn, a.clear, .result-number {
      margin-right: 8px;
    }

    .usa-input {
      border: solid 1px;
      float: none;
      height: 2.45rem;
      padding-left: 40px;
    }

    .icon-container {
      position: absolute;
      right: 0;
      display: flex;
      max-height: 100%;
      top: 16px;
    }
  }

  @media(max-width: 1023px) {
    .search-box-wrapper {
      display: none;
    }

    .search-box-wrapper.mobile-search-box {
      bottom: 0;
      position: fixed;
      margin-right: 0.5rem;
      right: 50%;
      transform: translateX(50%);
      width: auto;
      z-index: 9999;
      display: block;
      padding-bottom: 0;
      background-color: transparent;
      top: auto;

      .search-label {
        display: none;
      }

      a.searchbtn {
        display: none;
      }

      .usa-input {
        padding-left: .5rem;
        min-width: 100%;
      }

      .result-number {
        display: none;
      }

      .mobile-searchbar {
        background-color: #005EA2;
        color: white;
        position: absolute;
        right: 0;
        bottom: 0;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        width: 15%;
        height: 39px;

        .usa-icon {
          width: 24px;
          height: 24px;
        }
      }

      .search {
        display: none;
      }

      .icon-container {
        right: 12px;
        top: 18px;
      }

      .close-search {
        display: none;
      }

      &.visible {
        display: block;
        position: fixed;
        top: 14em;
        z-index: 999;
        background-color: white;
        padding: 1em;
        left: 23%;
        transform: translateX(-20%);
        box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.1);
        width: 90%;
        bottom: auto;
        right: auto;
        border-radius: 0.25rem;

        .search-label {
          display: block;
        }

        .close-search {
          display: block;
          position: absolute;
          right: 10px;
          top: 10px;
        }

        &.active {
          bottom: 75px;
          top: auto;
          padding-right: 3em;

          .clear {
            font-size: 8px;
            color: #71767A;
            text-decoration: none;
          }

          .result-number {
            display: block;
          }

          .close-search {
            display: block;
            position: absolute;
            right: 8px;
            top: 50px;
          }
        }
      }
    }
  }

  .section {
    position: relative;

    &.highlight {
      background-color: #FDFD96;
    }

    .search-term {
      background-color: #FDFD96;
      font-weight: bold;
    }

    .btn-group {
      display: flex;
      gap: 8px;
      position: relative;
      z-index: 2;

      @media(max-width: 55rem) {
        position: relative;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .subpart {
    position: relative;

    p {
      a {
        overflow-wrap: anywhere;
      }
    }

    .btn-group {
      display: flex;
      gap: 8px;
      position: relative;
      justify-content: start;
      z-index: 2;

      @media(max-width: 55rem) {
        position: relative;
      }

      a {
        text-decoration: none;
      }
    }
  }

  #crt-page--content {
    .crt-lead {
      > p,
      li {
        @include text--heading__medium();
      }
    }

    h1 {
      margin: 0;
    }

    h2:not(.usa-alert__heading):not(.usa-accordion__heading):not(.usa-card__heading):not(.ta-article-heading):not(.lp-tile-heading) {
      margin-top: 3rem;
      &:after {
        content: '';
        display: block;
        margin-top: 0.5rem;
        @extend .crt-landing--separator_sub !optional;
      }
    }

    h3:not(.usa-card__heading) {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }

    h4 {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

  }
}

#crt-page--content {
  .law-reg-tiles {
    margin-bottom: 2em;
    strong {
      display: block;
      padding-bottom: 0.5em;
    }
    :first-of-type.lp-tile  {
      margin-right: 2em;
      @media(max-width: 639px) {
        margin-right: 0;
      }
    }
    .lp-tile  {
      border: solid 1px #D9D9D9;
      padding: 0.5rem;
      @media(max-width: 639px) {
        flex: 0 1 100%;
      }
    }
    .lp-tile {
      .crt-landing--reporting_column {
        h2 {
          font-family: family("heading");
          font-weight: bold;
          font-size: 1.375rem;
        }
      }
    }
    .crt-landing--reporting_column {
      h3 {
        font-family: Public Sans Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-size: 22px;
        font-weight: normal;
        margin-top: 0;
      }
    }
  }
  }

.standards-and-guidance {
  display: flex;
  gap: 4rem;

  margin-bottom: 4rem;
  &:last-child {
    margin-bottom: 0;
  }

  @include at-media-max(tablet) {
    flex-direction: column;
  }

  .document {
    width: 100%;

    img {
      border: 1px solid lightgray;
      display: block;
      margin-bottom: 1em;
      max-height: 300px;
      max-width: 40%;
    }
  }
}

@media(max-width: 1023px) {
  .overlay {
    background-color: #D9D9D9;
    width: 100%;
    height:100%;
    z-index:10;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
}
