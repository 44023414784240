@use "uswds-core" as *;
@use 'variables' as *;

footer {
  width: 100%; // IE11 fix
  color: color("white");
}

.usa-footer__contact {
  display: flex;
  flex-direction: column;

  @include at-media-max(desktop) {
    margin-bottom: 2.5rem;
  }
}

.usa-footer__section {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;

  .usa-footer__icon__language {
    margin-top: 10px;
  }
}

.usa-footer__icon {
  margin-right: 1.5rem;
}

.usa-footer__logo {
  align-items: flex-start !important;

  img {
    height: 60px;
    @include at-media-max(mobile-lg) {
      height: 45px;
    }
  }

  .usa-button {
    box-shadow: inset 0 0 0 2px color($theme-color-warning);
    color: color($theme-color-warning) !important;
    margin-top: units(3);
    padding-left: units(2);
    padding-right: units(2);

    &:hover {
      box-shadow: inset 0 0 0 2px color($theme-color-warning-dark);
      color: color($theme-color-warning-dark) !important;
    }
  }

  .usa-tag {
    line-height: 1.15;
  }
}

.usa-footer__logo-section {
  margin-top: 1.25rem;
  margin-bottom: 3rem;
}

.usa-footer__logo-heading {
  @include text--heading__large();
  display: block;
  margin-bottom: 0;
  margin-top: 0.5rem;
  @include at-media-max(mobile-lg) {
    font-size: $theme-heading-font-size-md-mobile;
  }
  font-weight: bold;
  @include u-font-family("serif");
}

.usa-footer__logo-subheading {
  @include text--body-copy__xsmall();
  font-weight: bold;
  margin: 0;
  @include u-font-family("serif");
}

.usa-footer__heading {
  @include text--heading__medium();
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1em;
}

.usa-footer__separator {
  margin-top: 1rem;
  border: 2px solid color($theme-color-warning);
  border-radius: 2px;
  background: color($theme-color-warning);
  width: 25px;
}

.usa-footer__links {
  @include text--body-copy__small();
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  a {
    text-transform: uppercase;
    margin-bottom: 1.5em;
    &:hover {
      color: color($theme-link-dark-hover-color) !important;
    }
  }
}

.usa-footer__secondary-section {
  background-color: color($theme-color-base-ink);
  color: color("white");

  a,
  a:visited {
    color: color("white");
  }
}

.usa-footer__attribution {
  background-color: color("gray-warm-90");
  color: color("white");
  padding: 2.5rem 0;

  &-names {
    margin: 0.5rem 0;
  }
  p {
    @include text--body-copy__small();
    margin: 0;
  }
}
