@use "uswds-core" as *;
@use 'variables' as *;

.usa-pagination {
  align-items: center;
}

.usa-pagination__link[disabled] {
  color: color($theme-color-base);
  opacity: 1 !important;
  pointer-events: none;
}

.usa-pagination__link[disabled] .usa-icon {
  display: none;
}

.usa-pagination[hidden] {
  display: none !important;
}

// Overrides the USWDS styling for the pagination buttons
.usa-pagination__button {
  color: #f0f0f0 !important;
}

.usa-pagination__button:hover,
.usa-pagination__button:focus,
.usa-pagination__button.usa-current:hover,
.usa-pagination__button.usa-current:focus {
  background-color: #2378C3 !important;
  border: 1px solid transparent;
  color: #f0f0f0 !important;
}

.usa-pagination__button.usa-current {
  background-color: #FFFFFF !important;
  color: #2378C3 !important;
  border: 1px solid #2378C3;
}

.usa-pagination__link-text:hover,
.usa-pagination__link-text:focus {
    color: #2378C3;
}

.usa-pagination__item span:not(.usa-pagination__link-text) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
}
