@use "uswds-core" as *;
@use 'variables' as *;

.column-list {
  @include at-media(desktop) {
    column-count: 2;
  }
}

.fixed-tab-button {
  bottom: 0;
  position: fixed;
  padding: 1em 2em !important;
  border-radius: .25rem;
  margin-right: 0.5rem;
  right: 0;
  width: auto;
  z-index: 9999;
  font-size: 0.85em;
}

.fixed-search-button {
  bottom: 0;
  position: fixed;
  margin-right: 0.5rem;
  right: 50%;
  transform: translateX(50%);
  width: auto;
  z-index: 9999;
  font-size: 0.85em;
}

.grid-gap-xl {
  @include grid-gap(6);
}

.no-bullets {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.two-col.related-content-container .usa-summary-box__text {
  .icon-box {
    @media(max-width: 40em) {
      margin-bottom: 24px;
    }
  }

  .icon-box:last-child {
    @media(max-width: 40em) {
      margin-bottom: initial;
    }
  }

  @media(min-width: 40em) {
    gap: 24px;
  }
}

.footnotebody a {
  background-color: #fff;
}

.icon-box {
  display: flex;

  img,svg {
    fill: #162E51;
    margin-top: 1px;
    width: 22px;
    height: 22px;
    max-width: 22px;
  }

  .content-column {
    h3,h4 {
      display: inline;
      font-size: 1em;
      margin: 8px;

      img,svg {
        vertical-align: middle;
        margin-right: 5px;
      }

      span {
        vertical-align: middle;
      }
    }
  }
}
