@use "uswds-core" as *;
@use 'variables' as *;

// prevent scrolling when modal is open
body.is-modal {
  height: 100vw;
  overflow-y: hidden;

  @media print {
    main,
    header {
      display: none;
    }
  }
}

.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow-y: auto; // a11y: allow scroll for zoomed in modal

  @media print {
    display: none;
  }

  .modal-content {
    position: relative;
    background: white;
    @include u-radius("md");
    @include u-shadow(3);
  }

  .modal-content--small {
    width: 90%;
    padding: 1rem;
    @include at-media(tablet) {
      width: 85%;
      padding: 2rem;
    }
    @include at-media(desktop) {
      width: 50%;
      padding: 3rem;
    }
  }

  .modal-content--large {
    width: 80%;
    height: 60%;
    min-height: 40rem; // a11y: don't collapse modal
    padding: 2rem;
    @include at-media(tablet) {
      width: 70%;
      padding: 2rem;
    }
    @include at-media(desktop) {
      width: 50%;
      padding: 3rem;
    }
  }

  .modal-main {
    flex-grow: 1;
  }

  .modal-form {
    display: flex;
    flex-direction: column;
    height: calc(100% - 4rem);
  }

  .modal-guide {
    display: flex;
    flex-direction: column;
    height: calc(100% - 4rem);
    overflow-y: scroll;
    border-bottom: 1px solid color($theme-color-primary-darker);
  }

  h1:not(.h2) {
    color: color($theme-color-primary-darker);
    margin-top: 0;
    font-size: 2rem;
    border-bottom: 1px solid color($theme-color-primary-darker);
  }
}
