@use "uswds-core" as *;

@use 'variables' as *;

#crt-page--content {
  .usa-accordion__heading:not(:first-child),
  .usa-prose .usa-accordion__heading:not(:first-child) {
    margin-top: 1rem;
  }

  .netlify-preview .usa-accordion__content h2 {
    display: none;
  }

  .usa-accordion__button {
    @extend .h4 !optional;

    background-color: color($theme-color-primary-lightest);
    background-image: url("../images/remove--primary.svg");
    color: color($theme-color-primary-darker);

    &:hover {
      background-color: color($theme-color-primary-lighter);
    }

    &[aria-expanded="false"] {
      background-image: url("../images/add--primary.svg");
    }
  }

  .usa-accordion__content {
    padding: 1.5rem 1.25rem 2.5rem 1.25rem;
  }

  #expand-header-wrapper {
    flex-wrap: nowrap;
    @include at-media(mobile) {
      flex-direction: column;
    }
    @include at-media(tablet) {
      flex-direction: row;
    }
  }

  #expand-header-wrapper h2{
    @include at-media(mobile) {
      margin-top: 0.5rem;
    }
    @include at-media(tablet) {
      margin-top: 3rem;
    }
  }

  #expand-header-wrapper .button-container {
    @include at-media(mobile) {
      order: -1;
    }
    @include at-media(tablet) {
      order: 2;
    }
  }

  #crt-page--expandaccordions--wrapper {
    @include at-media(mobile) {
      float: left;
    }
    @include at-media(tablet) {
      float: right;
    }
   }

  .box-accordion {
    details > summary > div::before {
      all: unset;
    }

    details > summary {
      font-family: Public Sans Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      font-weight: normal;
      color: #1B1B1B;
      background-color: #F0F0F0;
      border-bottom: 0;
      padding: 1em;
      background-position: right 1em center;

      & > div {
        padding-right: 2em;
      }
    }

    details > div {
      background-color: transparent;
    }

    pre.address {
      font-family: inherit;
      font-size: inherit;

      display: flex;
      justify-content: center;
    }

    #crt-page--expandaccordions,#crt-page--expandaccordions:hover,#crt-page--expandaccordions:active,#crt-page--expandaccordions:visited {
      all: unset;
      color: #1B1B1B !important;
      cursor: pointer;
      margin-top: 2rem;
      text-decoration: underline;
    }
  }
}
