@use "uswds-core" as *;
@use 'variables' as *;

.usa-pagination__list [hidden] {
  display: none;
}

.usa-search--small [type="submit"] {
  background-color: color($theme-color-primary-darker);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.5rem;
}

.usa-search.filters [type="submit"] {
  margin-top: 1em;
  height: auto;
  padding: 0;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: color($theme-color-primary-darker);
  cursor: pointer;
}

.search-filters {
  .usa-checkbox {
    display: flex;
    align-items: baseline;
    .usa-tooltip {
      padding-left: 5px;
    }
    .usa-checkbox__label {
      display: inline-block;
    }
    svg.usa-icon {
      height: 18px;
      width: 18px;
    }
  }
}

label.usa-sr-only {
  color: white;
}

.best-bet-item {
  flex: 0 1 100%;
}

@include at-media(mobile) {

  .usa-search--small [type="submit"],
  .usa-search--small .usa-search__submit {
    min-width: 5rem !important;
  }
}

@include at-media(desktop) {

  input#query {
    max-width: 20ch !important;
  }

  .usa-search--small input[type="search"] {
    border-color: color("white");
  }

  .usa-search--small [type="submit"],
  .usa-search--small .usa-search__submit {
    background-color: color($theme-color-base-lighter);
    color: color($theme-color-primary-darker);
    min-width: 5rem !important;

    &:hover {
      background-color: color("gray-cool-20");
    }
  }

  .best-bet-item {
    flex: 0 1 48%;
  }

  .single-result .best-bet-item {
    flex: 0 1 100%;
  }
}
