@use "uswds-core" as *;
@use 'variables' as *;

.crt-landing--logo_section {
  margin: 0;
  padding: 0;

  a:hover,
  a:visited:hover {
    text-decoration: none;

    &:hover {
      color: color($theme-color-primary-dark);
    }
  }

  img {
    height: 60px;
    @include at-media-max(mobile-lg) {
      height: 45px;
    }
  }
}

.usa-header {
  .crt-phone {
    a {
      color: white;
      font-weight: normal;
      text-decoration: none;
      border-bottom: 1px solid;
      &:focus, &:hover, &:visited {
        color: white;
        font-weight: bolder;
      }
    }
}

}

.crt-landing--logo_subheading {
  @extend .usa-footer__logo-subheading !optional;
}

.crt-landing--logo_heading {
  @extend .usa-footer__logo-heading !optional;
}

.usa-menu-btn {
  @include u-radius("md");
  padding: 0.75rem 1.25rem;
  text-transform: unset;
  background: color($theme-color-primary-darker);
  color: color($theme-color-primary-lightest);
  max-height: 3rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 700;
}

.usa-header--extended .usa-navbar {
  height: auto !important;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.usa-logo a {
  color: color($theme-color-primary-dark);
}

.usa-nav {
  @include at-media-max(desktop) {
    width: 18rem;
  }

  &.is-visible {
    background-color: color($theme-color-primary-lightest);
    color: color($theme-color-primary-darker);
    @include u-shadow(2);
  }
}

.usa-nav__submenu {
  .usa-nav__submenu-item {
    font-weight: bold;
    .TheADA {
      font-weight: normal;
      margin: 0 20px;
    }
  }
}

.usa-nav__subnav_child_menu {
  list-style: none;
  padding: 0 20px;
  .usa-nav__submenu-item {
    font-weight: normal;
  }
}

.usa-header--extended .usa-nav__inner {
  display: flex;
  flex-direction: column;
}

@include at-media(desktop) {
  .usa-header--extended {
    .usa-nav {
      background: color($theme-color-primary-darker);
      border-top: 0;
    }

    .usa-navbar {
      padding-top: 0;
      padding-bottom: 0;
    }

    .usa-nav__inner {
      display: block;
    }

    .usa-nav__primary > .usa-nav__primary-item > a,
    .usa-nav__primary button {
      color: color("white");
      font-weight: normal;
      padding: 1.25rem 1rem;
    }

    .usa-nav__primary button[aria-expanded="false"] {
      background-image: url("../images/uswds/sprite.svg#expand_more");
      ::after {
        background-color: color("white");
      }
      :hover {
        span::after {
          background-color: color("white");
        }
      }
    }

    .usa-nav__primary-item > .usa-current::after,
    .usa-nav__primary-item > .usa-nav__link:hover::after {
      background: color("white");
      bottom: 0.5rem;
    }
  }
  .usa-nav__secondary {
    bottom: 0.75rem;

    @media (min-width: 1024px) and (max-width: 1100px) {
      min-width: 15rem;
    }
  }
}
