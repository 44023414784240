@use "uswds-core" as *;
@use 'variables' as *;

.crt-dropdown {
  align-items: center;
  background: color('white');
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  min-height: 2.5rem;
  min-width: 6rem;
  width: 18rem;
  margin-right: 1rem;
  margin-bottom: 2rem;

  &:focus-within {
    .content {
      outline: initial;
    }
  }

  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 20px 10px hsla(0, 0%, 39.2%, 0.2);
  }

  &--stroke {
    @include u-border($theme-color-primary-darker);
    border: 1px solid;
  }

  button {
    width: 100%;
  }

  .crt-dropdown-container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include flex-container();
    background-color: color('white');
    box-shadow: none;
    cursor: pointer;
    font-size: $theme-text-font-size-md;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: left;
    width: 100%;
    border: 1px solid #162E51;
    border-radius: 3px;

    .label {
      margin-right: 1rem;
    }

    * {
      pointer-events: none;
    }
  }

  select.complaint-multi-select {
    background-image: none;
  }

  .content {
    background: color('white');
    border-radius: 3px;
    box-shadow: 0 4px 4px 0px hsla(0, 0%, 39.2%, 0.2);
    min-width: 16rem;
    position: relative;
    width: 100%;
    }

  .icon,
  svg {
    height: 1rem;
    margin-left: auto;
    width: 1rem;
  }
}
.crt-dropdown:last-of-type {
  margin-top: 0rem;
}

.crt-dropdown-container {
  display: flex;
  flex-direction: column;
}

#expand-less {
  display: none;
}

#category-filter-list {

  list-style-type: none;

  li::-webkit-details-marker {
    display:none;
  }
  li::marker {
    display:none;
  }
}