// Overwrite the icon used by the alert banner on the homepage:
.usa-site-alert--info .usa-alert::before {
    background: url("../images/uswds/usa-icons/warning.svg") no-repeat center/contain;
    -webkit-mask: url("../images/uswds/usa-icons/warning.svg") no-repeat center/contain;
    mask: url("../images/uswds/usa-icons/warning.svg") no-repeat center/contain;
}

// Change the default alert border color and add a small left hand border to the alert
.warning-banner {
    background-color: #FBF3D1;

    .usa-alert__heading {
        background-color: #FBF3D1;
    }
}

.usa-site-alert--info .usa-alert {
    background-color: #FBF3D1;
}

// Change the anchor link styling to primary color darker
.warning-banner a:hover {
    color: #1a4480;
    text-decoration: underline;
}
