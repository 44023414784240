@forward 'usa-table/src/styles';
@use 'variables' as *;


table {
    @extend .usa-table;

    caption {
      background-color: white;
    }
  }

@media(max-width: 1023px) {
  .table-wrapper {
    max-width: 100vw;
    overflow-x: scroll;
  }
}
