// CUSTOM SETTINGS
@use 'uswds-core' with (
  // Base colors
  $theme-color-base-family: 'gray',
  $theme-color-base-light: 'gray-30',
  $theme-color-base: 'gray-50',
  $theme-color-base-darker: 'gray-70',
  $theme-color-base-ink: 'gray-warm-80',
  // Primary colors
  $theme-color-primary-lightest: 'blue-warm-5',
  $theme-color-primary-lighter: 'blue-warm-10',
  $theme-color-primary: 'blue-warm-70v',
  // Links
  $theme-link-color: 'blue-warm-80v',
  $theme-link-visited-color: 'blue-warm-80v',
  $theme-link-hover-color: 'blue-50',
  // Accordion
  $theme-accordion-font-family: 'heading',
  // Banner
  $theme-banner-background-color: 'white',
  $theme-banner-link-color: 'primary-darker',
  $theme-banner-max-width: 95rem,
  // Header
  $theme-header-max-width: 95rem,
  // Footer
  $theme-footer-max-width: 95rem,
  // Hero
  $theme-hero-image: '../images/hero-flag.jpg',
  // Site Alert
  $theme-site-alert-max-width: 95rem,
  $theme-image-path: '../images/uswds',
  $theme-show-compile-warnings: false,
  $theme-show-notifications: false,
  $theme-focus-offset: 2px,
  $theme-focus-width: 2px,
  $theme-grid-container-max-width: 95rem,
  $theme-font-path: '../fonts',
  // sans-serif
  $theme-font-type-sans: 'public-sans',
  $theme-utility-breakpoints: (
    // 160px:
    'card': false,
    // 240px:
    'card-lg': false,
    // 320px:
    'mobile': true,
    // 480px:
    'mobile-lg': true,
    // 640px:
    'tablet': true,
    // 800px:
    'tablet-lg': false,
    // 1024px:
    'desktop': true,
    // 1200px:
    'desktop-lg': true,
    // 1400px:
    'widescreen': true
  ),
  $global-color-palettes: ('palette-color-default', 'palette-color-state'),
  $align-items-settings: (responsive: true),
  $whitespace-settings: (responsive: true),
  $width-settings: (responsive: false),
  $margin-manual-values: (12: 6rem, 16: 8rem, 20: 10rem)
);
