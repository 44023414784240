@use "uswds-core" as *;
@use 'variables' as *;

.usa-collection__meta-item time {
    font-size: 1rem;
}

.usa-collection__description {
    @include at-media(mobile-lg) {
        max-width: 55ch;
    }
    @include at-media(desktop) {
        max-width: 85ch;
    }
}

.common-links {
  padding: 1em;
  background-color: #ECF1F7;

  h2 {
    margin-top: 0.5em !important; // Overrides overly selective crt-page styles
  }

  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1em;
    flex-wrap: wrap;

    @include at-media(desktop) {
        height: calc((26px + 1em)* 7);
    }

    li a {
      color: #005EA2;
    }
  }
}
