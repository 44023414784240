@use "uswds-core" as *;
@use 'variables' as *;

.crt-page.grid-container .usa-sidenav {
  margin-left: 0;
  margin-right: 0;
  &.interactive {
    padding-top: 80px;
  }
}

#crt-page--sidenav {
  @include at-media(desktop) {
    max-height: calc(100vh - 3rem);
    display: flex;
    flex-direction: column;
  }

  .usa-sidenav__item {
    .display-flex {
      display: flex;
    }
  }

  a#expand-all {
    color: white;
    background-color: #162e51;
    font-weight: bold;
    border-radius: 0.25rem;
    margin-bottom: 8px;
    display: flex;
    .expand-text {
      color: white;
    }
  }

  .usa-button.side-nav-button {
    background-color: #E1E7F1;
    color: #1A4480;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &.margin-right-0 {
      margin-right: 0;
    }
    &.margin-right-1 {
      margin-right: 8px;
    }
    .usa-icon {
      padding-left: 2px;
    }
  }

  .subpagenav {
    .usa-current::after {
      background-color: #162e51;
      border-radius: 99rem;
      content: "";
      display: block;
      position: absolute;
      bottom: 0.25rem;
      top: 0.25rem;
      width: 0.25rem;
      left: 0;
    }

  }

  a:not(.crt-page--downloadpdf-button)  {
    color: color($theme-text-color);

    &.usa-current {
      color: color($theme-color-primary-darker);

      &:after {
        background-color: color($theme-color-primary-darker);
      }
    }
  }

  .usa-sidenav__sublist {
    li {
      a {
        &:focus {
          background-color: color($theme-color-base-lightest);
        }
      }
    }
    .crt-sidenav-subnav-item{
      font-family: Public Sans Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
    }

    li.usa-accordion {
      // margin-bottom: 2rem;
      margin-top: 0px;
      @include at-media(desktop) {
        overflow-y: visible;
        display: inline;
      }

      .usa-accordion__content {
        box-shadow: none;
      }
    }

    * .usa-accordion__button {
      color: #2e2e2a;
      background-image: url("../images/remove.svg");
      background-color: transparent;
      border-radius: 0px;
      font-size: 1rem;
      padding-left: 2rem;
      font-family: Public Sans Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: rgb(223, 225, 226);
      padding-bottom: 0.25rem;

      &[aria-expanded="false"] {
        background-image: url("../images/add.svg");
      }
    }
  }

  .usa-accordion .usa-summary-box__heading .usa-accordion__button {
    @extend .h4 !optional;
    background-color: color($theme-color-primary-lighter);
    color: color($theme-color-primary-darker);
    background-image: url('../images/remove.svg');

    &[aria-expanded='false'] {
      background-image: url('../images/add.svg');
    }
  }

  .usa-accordion .usa-summary-box__text {
    a {
      @include u-font-family('sans');
    }
  }

  .usa-summary-box__link {
    &:hover,
    &:focus {
      color: color($theme-color-primary-vivid);
    }
  }

  .usa-accordion:not(.usa-summary-box__body) {
    // margin-bottom: 2rem;

    @include at-media(desktop) {
      max-height: calc(100vh - 1.5rem * 2);
      overflow-y: auto;
    }
  }

  .usa-accordion__button {
    background-color: color($theme-color-primary-darker);
    background-image: url('../images/uswds/usa-icons-bg/remove--white.svg');
    color: color('white');
    font-weight: normal;
    @include u-radius('md');

    &[aria-expanded='false'] {
      background-image: url('../images/uswds/usa-icons-bg/add--white.svg');
    }
  }

  .usa-accordion__content {
    background-color: color($theme-color-primary-lightest);
    @include u-shadow(2);
  }

    @include at-media(desktop) {
    @include u-position('sticky');
    @include u-top(3);
    @include u-flex('align-self-start');

    .usa-accordion__content {
      background: none;
      padding: 0;
      @include u-shadow('none');

      &[hidden] {
        display: block;
      }
    }

    .usa-sidenav__sublist {
      a {
        @include text--body-copy__small();
      }
      * .usa-accordion__button {
        color: #2e2e2a;
        @include text--body-copy__small();
        &:focus, &:hover {
          background-color: color($theme-color-base-lightest);
        }
    }

      * .usa-accordion__content {
        &[hidden] {
          display: none;
        }
      }

      li.active {
        font-weight: bold;

        a {
          color: color($theme-color-primary-darker);
        }
      }
    }
  }
}

.phone-box {
  padding: 0.5em 1em;
  font-size: .875rem;
  background-color: #E6E6E6;
  font-family: Public Sans Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
}

@media(max-width: 1023px) {
  .crt-page.grid-container .usa-sidenav {
    &.interactive {
      padding-top: 0;
    }
  }
}
