.crt-page {

    .usa-breadcrumb {
        display: flex;
        flex-direction: row;
        padding-bottom: 2rem;
    }

    svg.usa-icon {
        align-self: center;
        height: 22px;
        width: 22px;
    }

}