figure {

    img {
        margin-top: 1rem;
        border: 1px black solid;
    }

    figcaption {
        padding-top: 1rem;
    }
}
